import React, { useState, useEffect } from "react"
import "../components/layout.css"
import Iframe from "react-iframe"
import Seo from "../components/seo"
import axios from "axios"
import SelectPopup from "../components/SelectPopup";
import Select from "react-select";
import {isMultiSelect, selectOptions, selectTitle} from "../utils/options"

const BotPage = () => {
    const name = "jr_tech"
    const feedback = "No"
    const popUpTheme = '#083E60';

    const [loading, setLoading] = useState(true);
    const [selection, setSelection] = useState([]);
    const [variableName, setVariableName] = useState("");
    const [visibility, setVisibility] = useState(false)
    const [session, setSession] = useState("no sesion");

    const headers = {
        Authorization: "bearer 40b3ff5fdeaf4ca6851eecadd6eec23c",
    }

    useEffect(() => {
        getBotId();
        eventSubscribe();
    }, [])

    const getBotId = async () => {
        try {
            const getBotId = await axios({
                method: "get", //you can set what request you want to be
                url:
                    "https://api.33bot.io/v1/conversation/chat/632b107ff2599d000982e1b6/bots",
                headers,
            })
            //const bot_id = getBotId.data[0].id

            const newSession = await axios({
                method: "post", //you can set what request you want to be
                url: "https://api.33bot.io/v1/conversation/create",
                data: {
                    bot_id: getBotId.data[0].id,
                },
                headers,
            })

            const someSession = newSession.data.id
            setSession(someSession)

            const updateSession = await axios({
                method: "post", //you can set what request you want to be
                url: "https://api.33bot.io/v1/conversation/update",
                data: {
                    session_id: someSession,
                    global_vars: {
                        candidatura_seleccionada: {
                            text: name,
                            value: name,
                        },
                        feedback: {
                            text: feedback,
                            value: feedback,
                        },
                    },
                },
                headers,
            })

            if (updateSession.data.status === "ok") {
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
        }
        console.log(`iniciando chatbot con sesión ${session}`)
    }

    const updateData = async () => {
        const valueData = selection.map(sel => sel.value)

        let dataVars = {};
        dataVars[variableName] = {
            text: valueData.join(', '),
            value: valueData.join(', ')
        };

        await axios({
            method: "post", //you can set what request you want to be
            url: "https://api.33bot.io/v1/conversation/update",
            data: {
                session_id: session,
                global_vars: dataVars,
            },
            headers,
        })

        const labelData = selection.map(sel => sel.label)

        await axios({
            method: "post", //you can set what request you want to be
            url: "https://api.33bot.io/v1/conversation/message/user",
            data: {
                session_id: session,
                text: labelData.join(', '),
            },
            headers,
        })
    }

    let popupCloseHandler = () => {
        updateData()
        setVisibility(false)
        setSelection([])
    }

    let changeHandler = value => {
        if (value[0]) {
            setSelection([...value])
        } else {
            setSelection([value])
        }
    }

    const eventSubscribe = () => {
        window.addEventListener(
            "message",
            function (event) {
                const data = event.data
                if (event.data.event) {
                    switch (data.event) {
                        case "select":
                            setVariableName(data.variable)
                            setVisibility(true);
                            break;
                        default:
                            console.log(data)
                    }
                }
            },
            false
        )
    }

    return (
        <>
            <Seo title="Trabaja en Modern Talent Hub" />
            <div style={{ width: "100vw", height: "100vh" }}>
                {loading ? (
                    <div>Cargando...</div>
                ) : (
                    <Iframe
                        url={`https://chat.33bot.io/632d7d7fdaebe70009201ad7?r=web&close=0&session=${session}`}
                        width="100%"
                        height="100%"
                        allow="camera;microphone"
                        frameborder="0"
                    />
                )}
                <SelectPopup
                    onClose={popupCloseHandler}
                    show={visibility}
                    theme={popUpTheme}
                    title={selectTitle[variableName]}
                >
                    <Select
                        isMulti={!!(isMultiSelect.hasOwnProperty(variableName) && isMultiSelect[variableName])}
                        className={variableName === 'technologies' ? 'basic-multi-select' : 'basic-single'}
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        name={variableName}
                        value={selection}
                        options={ selectOptions[variableName] }
                        onChange={changeHandler}
                        placeholder={"Selecciona..."}
                    />
                </SelectPopup>
            </div>
        </>
    )
}

export default BotPage
